<template>
  <BreadCrumb PageTitle="Vida &uacute;til de veh&iacute;culos" Subtitle="Reportes"></BreadCrumb>
  <CarLifeComponent></CarLifeComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import CarLifeComponent from "@/components/Reports/CarLifeComponent.vue"

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, CarLifeComponent}
}
</script>

<style scoped>

</style>
