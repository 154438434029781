<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing ps-4 pe-4">
    <div class="card-head box-shadow bg-white row p-2">
      <div class="col-lg-2 col-sm-12 d-flex align-items-center justify-content-start">
        <MazCheckbox v-model="typeFilter" label="Diario"/>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex justify-content-between align-items-center">
        <VueDatePicker v-model="date_init" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                       teleport-center
                       format="dd/MM/yyyy hh:mm aa"
                       time-picker-inline placeholder="Seleccione la fecha"/>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex justify-content-between align-items-center">
        <VueDatePicker v-model="date_end" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                       teleport-center v-show="!typeFilter"
                       :invalid="date_end===null"
                       format="dd/MM/yyyy hh:mm aa"
                       time-picker-inline placeholder="Seleccione la fecha"/>
      </div>
      <div class="col-lg-2 col-sm-12 d-flex justify-content-between align-items-center">
        <button class="btn btn-link" @click="loadDatas" target="_blank" title="Buscar ...">
          <i class="flaticon-search"></i>
        </button>
        <button class="btn btn-link" @click="generateReportPDF" target="_blank" title="Descargar reporte en pdf">
          <i class="flaticon-download-circular-button"></i>
        </button>
      </div>
    </div>
    <div class="card-head box-shadow bg-white row p-2">
      <div class="col-lg-2 col-sm-12 d-flex align-items-center justify-content-start">
      <select class="form-select" v-model="service">
        <option :value="0">Todos</option>
        <option v-for="item in extras" :value="item.id">{{item.name}}</option>
      </select>
      </div>
      <div class="col-lg-6 col-sm-12 d-flex align-items-center justify-content-start">

      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-center justify-content-start">

      </div>
      </div>
    <div ref="report" class="card-body p-15 p-sm-20 p-md-25 bg-light text-start" style="color:#0c0c0c">
      <div class="row text-start mb-20">
        <strong>Informe de servicios extras por per&iacute;odo</strong>
        <strong>Fecha:</strong><span>{{
          date_init ? formatearFechaShort(date_init) : ""
        }}{{ date_end ? " / " + formatearFechaShort(date_end) : "" }}</span>
      </div>
      <div class="row text-start mb-20">
        <div class="col-lg-3 col-sm-6"><strong>Monto de los servicios extras en el per&iacute;odo:</strong></div>
        <div class="col-lg-3 col-sm-6">{{ this.values ? transfMount(this.values.totalExtras) : "$ 0.00 MXN" }}</div>
        <div class="col-lg-3 col-sm-6"></div>
        <div class="col-lg-3 col-sm-6"></div>
      </div>
      <p class="mb-3" style="margin-top:20px"><strong>Contrataci&oacute;n servicios extras</strong></p>
      <div class="row text-start">
        <div class="col-lg-3 col-sm-6"><strong>Servicio extra</strong></div>
        <div class="col-lg-3 col-sm-6"><strong>Monto</strong></div>
        <div class="col-lg-3 col-sm-6"><strong>Porciento</strong></div>
        <div class="col-lg-3 col-sm-6"></div>
      </div>
      <div class="row text-start mb-5" v-for="(item, index) in services" :key="index" v-show="item.monto>0">
        <div class="col-lg-3 col-sm-6">{{ item.service }}</div>
        <div class="col-lg-3 col-sm-6">{{ transfMount(item.monto) }}</div>
        <div class="col-lg-3 col-sm-6">{{ parseFloat(item.porciento).toFixed(2) }} %</div>
        <div class="col-lg-3 col-sm-6"></div>
      </div>
      <div class="row text-start">
        <div class="col-lg-3 col-sm-6"><strong>Total</strong></div>
        <div class="col-lg-3 col-sm-6"><strong>{{ transfMount(this.values?this.values.totalExtras:0) }}</strong></div>
        <div class="col-lg-3 col-sm-6"></div>
        <div class="col-lg-3 col-sm-6"></div>
      </div>
      <p class="mb-3" style="margin-top:20px"><strong>Detalles de contratos</strong></p>
      <div class="mb-20">
        <div class="row text-start">
          <div class="col-lg-2 col-sm-6"><strong>No. Contrato</strong></div>
          <div class="col-lg-4 col-sm-6"><strong>Rango de fechas</strong></div>
          <div class="col-lg-3 col-sm-6"><strong>Veh&iacute;culo</strong></div>
          <div class="col-lg-3 col-sm-6"><strong>Total de servicios extras</strong></div>
        </div>
        <div class="row text-start" v-for="(contract, index) in contracts" :key="index">
          <div class="col-lg-2 col-sm-6">{{ contract.contratoNo }}</div>
          <div class="col-lg-4 col-sm-6">{{ formatearFecha(contract.fechaSalida) }} -
            {{ formatearFecha(contract.fechaRegreso) }}
          </div>
          <div class="col-lg-3 col-sm-6">{{ contract.vehiculo ? contract.vehiculo.noEconomico : "" }}</div>
          <div class="col-lg-3 col-sm-6">{{ transfMount(contract.totalServicios) }}</div>
        </div>
        <div class="row text-start">
          <div class="col-lg-2 col-sm-6"><strong>Total</strong></div>
          <div class="col-lg-4 col-sm-6"><strong></strong></div>
          <div class="col-lg-3 col-sm-6"><strong></strong></div>
          <div class="col-lg-3 col-sm-6"><strong>{{ transfMount(totalContracts) }}</strong></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  getDayExtra,
  getDayIncome,
  getOpenCloseReporte, getPeriodExtra,
  getPeriodIncome,
  getServicesForContract,
  guardarReservaPdf,
  reserveById
} from "@/actions";
import {decodeBase64, transfMount} from "@/util";
import html2pdf from "html2pdf.js";
import moment from "moment/moment";
import MazCheckbox from 'maz-ui/components/MazCheckbox'

export default {
  name: "CarService",
  components: {MazCheckbox},
  data() {
    return {
      values: null,
      service: 0,
      extras: [],
      date_init: null,
      date_end: null,
      loading: false,
      typeFilter: false,
      option: "noOption",
    }
  },
  created() {
    this.loadExtras()
  },
  methods: {
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm a");
    },
    formatearFechaShort(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY");
    },
    transfMount,
    async generateReportPDF() {
      this.accion = "print"
      this.loading = true
      setTimeout(() => {
        let base64Data = ""
        const contentToExport = this.$refs.report;
        const options = {
          margin: [17, 5, 20, 5],
          filename: "report.pdf",
          image: {type: "jpeg", quality: 0.98},
          html2canvas: {scale: 2},
          jsPDF: {unit: "mm", format: "a4", orientation: "portrait"}
        };
        html2pdf()
            .from(contentToExport)
            .set(options)
            .output("blob", "reporte.pdf")
            .then(dataUri => {
              const blob = new Blob([dataUri], {type: "application/pdf"});
              const url = URL.createObjectURL(blob);
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                base64Data = reader.result
              }
              window.open(url, "_blank");
            });
      }, 50);
      this.loading = false
    },
    loadDatas() {
      if (this.typeFilter) {
        if (this.date_init===null){
          return false
        }
        getDayExtra({
          date: this.date_init,
          service:this.service
        }).then(resp => {
          this.values = resp.data.data.reportDayExtra
        })
      } else {
        if (this.date_init===null || this.date_end===null){
          return false
        }
        getPeriodExtra({
          date_init: this.date_init,
          date_end: this.date_end,
          service:this.service
        }).then(resp => {
          this.values = resp.data.data.reportPeriodExtra
        })
      }
    },
    loadExtras() {
      getServicesForContract().then(resp => {
        this.extras = resp.data.data.serviciosExtrasApp
      })
    }
  },
  computed: {
    protections() {
      if (this.values) {
        return this.values.protections
      }
    },
    contracts() {
      if (this.values) {
        return this.values.contracts
      }
    },
    services() {
      if (this.values) {
        return this.values.services
      }
    },
    totalContracts() {
      let total = 0
      if (this.values) {
        this.values.contracts.forEach(item => {
          total += parseFloat(item.totalServicios)
        })
      }
      return total
    },
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
