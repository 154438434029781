<template>
  <BreadCrumb PageTitle="Listado de servicios de reparaci&oacute;n" Subtitle="Mantenimiento"></BreadCrumb>
  <RepairService></RepairService>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import RepairService from "@/components/Maintenance/RepairServiceComponent.vue";

export default {
  name: "RepairServiceComponent",
  components: {BreadCrumb, RepairService}
}
</script>

<style scoped>

</style>
