<template>
  <BreadCrumb PageTitle="Ventas por agentes" Subtitle="Reportes"></BreadCrumb>
  <AgentsByDateComponent></AgentsByDateComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import AgentsByDateComponent from "@/components/Reports/AgentsByDateComponent.vue"

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, AgentsByDateComponent}
}
</script>

<style scoped>

</style>
