<template>
  <div
      :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: SidenavOpen },
    ]"
      id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
          to="/auth/dashboard"
          class="d-flex align-items-center text-white text-decoration-none"
      >
        <img v-if=SidenavOpen src="http://srac-store-qa.s3.us-east-1.amazonaws.com/Portal/Imagenes/logo3.png"
             style="width: 30px; height: 30px;" alt="logo-icon"/>
        <img v-else src="http://srac-store-qa.s3.us-east-1.amazonaws.com/Portal/Imagenes/logo3.png"
             style="width: 50px; height: 50px;" alt="logo-icon"/>
        <span class="fw-bold ms-10">SRAC</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
          class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
          @click="onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
          class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion text-start"
          id="sidebarNavAccordion"
      >
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Seguridad"
            v-show="tienePermiso('access_to_security')"
        >
          <a id="Seguridad_link"
             @click="Show_submenu('Seguridad')"
             class="accordion-button rounded-0 shadow-none bg-transparent d-block collapsed cursor-pointer"
             data-bs-toggle="collapse"
             data-bs-target="#sidebarCollapseOne"
             aria-expanded="true"
             aria-controls="sidebarCollapseOne"
          >
            <i class="flaticon-shield-1 cursor-pointer"></i>
            <span class="title cursor-pointer">Seguridad</span>
          </a>
          <div
              id="Seguridad_submenu"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_users')">
                  <router-link to="/auth/seguridad/usuarios" class="sidebar-sub-menu-link">
                    Usuarios
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_groups')">
                  <router-link to="/auth/seguridad/roles" class="sidebar-sub-menu-link">
                    Roles
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_permissions')">
                  <router-link to="/auth/seguridad/permisos" class="sidebar-sub-menu-link">
                    Permisos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_setPermissions')">
                  <router-link to="/auth/seguridad/permisosByRol" class="sidebar-sub-menu-link">
                    Permisos por roles
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('IpAuth')">
                  <router-link to="/auth/seguridad/ipAutorizadas" class="sidebar-sub-menu-link">
                    Ip autorizadas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('IpAuth')">
                  <router-link to="/auth/seguridad/authorize" class="sidebar-sub-menu-link">
                    Autorizar clientes
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Administracion"
            v-show="tienePermiso('access_to_administrator')">
          <a id="Nomencladores_link"
             @click="Show_submenu('Nomencladores')"
             class="accordion-button rounded-0 shadow-none bg-transparent d-block collapsed cursor-pointer"
             data-bs-toggle="collapse"
             data-bs-target="#sidebarCollapseOne"
             aria-expanded="true"
             aria-controls="sidebarCollapseOne"
          >
            <i class="flaticon-settings   cursor-pointer"></i>
            <span class="title cursor-pointer">Administraci&oacute;n</span>
          </a>
          <div
              id="Nomencladores_submenu"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_country')">
                  <router-link to="/auth/nomencladores/paises" class="sidebar-sub-menu-link">
                    Pa&iacute;ses
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_carBrand')">
                  <router-link to="/auth/nomencladores/marcas" class="sidebar-sub-menu-link">
                    Marcas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_categories')">
                  <router-link to="/auth/nomencladores/categorias" class="sidebar-sub-menu-link">
                    Categor&iacute;as
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_offices')">
                  <router-link to="/auth/nomencladores/sucursales" class="sidebar-sub-menu-link">
                    Sucursales
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_extraServices')">
                  <router-link to="/auth/nomencladores/servicios_extras" class="sidebar-sub-menu-link">
                    Servicios extras
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_dropOff')">
                  <router-link to="/auth/nomencladores/dropoff" class="sidebar-sub-menu-link">
                    DropOff
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_protections')">
                  <router-link to="/auth/nomencladores/protecciones" class="sidebar-sub-menu-link">
                    Protecciones
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_origin')">
                  <router-link to="/auth/nomencladores/procedencias" class="sidebar-sub-menu-link">
                    Procedencias
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_company')">
                  <router-link to="/auth/nomencladores/empresas" class="sidebar-sub-menu-link">
                    Empresas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_commissionist')">
                  <router-link to="/auth/nomencladores/comisionista" class="sidebar-sub-menu-link">
                    Comisionistas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_promotions')">
                  <router-link to="/auth/nomencladores/promociones" class="sidebar-sub-menu-link">
                    Promociones
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_usePromotions')">
                  <router-link to="/auth/nomencladores/uso_promociones" class="sidebar-sub-menu-link">
                    Uso de promociones
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_maintenanceReason')">
                  <router-link to="/auth/nomencladores/maintenance-reason" class="sidebar-sub-menu-link">
                    Motivos mantenimiento
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="tienePermiso('access_to_seasons')">
                  <router-link to="/auth/nomencladores/temporadas" class="sidebar-sub-menu-link">
                    Temporadas altas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="this.$store.state.rol==='Administrador'">
                  <router-link to="/auth/nomencladores/appVersion" class="sidebar-sub-menu-link">
                    Versiones de la App
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-show="this.$store.state.rol==='Administrador'">
                  <router-link to="/auth/config-prodigia" class="sidebar-sub-menu-link">
                    Prodigia
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Clientes"
            v-show="tienePermiso('access_to_clients')">
          <a
              @click="$router.push({name: 'Clientes'})"
              class="sidebar-nav-link d-block cursor-pointer"
              :class="getRoute() === 'Clientes' ? 'active' : ''">
            <i class="flaticon-employee cursor-pointer"></i>
            <span class="title cursor-pointer">Clientes</span>
          </a>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Tarifas"
            v-show="tienePermiso('access_to_prices')">
          <a
              @click="$router.push({name: 'Tarifas'})"
              class="sidebar-nav-link d-block cursor-pointer"
              :class="getRoute() === 'Tarifas' ? 'active' : ''">
            <i class="ph ph-currency-dollar"></i>
            <span class="title cursor-pointer">Tarifas</span>
          </a>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Unidades"
            v-show="tienePermiso('access_to_cars')">
          <a id="cars_link"
             @click="Show_submenu('cars')"
             class="accordion-button rounded-0 shadow-none bg-transparent d-block collapsed cursor-pointer"
             data-bs-toggle="collapse"
             data-bs-target="#sidebarCollapseOne"
             aria-expanded="true"
             aria-controls="sidebarCollapseOne"
          >
            <i class="ph ph-car  cursor-pointer"></i>
            <span class="title cursor-pointer">Unidades</span>
          </a>
          <div
              id="cars_submenu"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/nomencladores/autos" class="sidebar-sub-menu-link">
                    Listado
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/unidades/maintenance" class="sidebar-sub-menu-link">
                    Mantenimientos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/unidades/serviceRepair" class="sidebar-sub-menu-link">
                    Servicios de reparaci&oacute;n
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item Reservas" v-show="tienePermiso('access_to_reserves')">
          <a @click="$router.push({name: 'Reservas'})" class="sidebar-nav-link d-block cursor-pointer"
             :class="getRoute() === 'Reservas' ? 'active' : ''">
            <i class="flaticon-date "></i>
            <span class="title">Reservas</span>
          </a>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Contratos"
            v-show="tienePermiso('access_to_contracts')">
          <a id="Contratos_link"
             @click="Show_submenu('Contratos')"
             class="accordion-button rounded-0 shadow-none bg-transparent d-block collapsed cursor-pointer"
             data-bs-toggle="collapse"
             data-bs-target="#sidebarCollapseOne"
             aria-expanded="true"
             aria-controls="sidebarCollapseOne"
          >
            <i class="flaticon-form  cursor-pointer"></i>
            <span class="title cursor-pointer">Contratos</span>
          </a>
          <div
              id="Contratos_submenu"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/contratos" class="sidebar-sub-menu-link">
                    Todos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/contratos/abiertos" class="sidebar-sub-menu-link">
                    Abiertos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/contratos/vencidos" class="sidebar-sub-menu-link">
                    Vencidos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/contratos/cerrados" class="sidebar-sub-menu-link">
                    Cerrados
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/contratos/cancelados" class="sidebar-sub-menu-link">
                    Cancelados
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Notificaciones"
            v-show="tienePermiso('access_to_notifications')">
          <a
              @click="$router.push({name: 'NotificationsView'})"
              class="sidebar-nav-link d-block cursor-pointer"
              :class="getRoute() === 'NotificationsView' ? 'active' : ''">
            <i class="ph ph-bell"></i>
            <span class="title cursor-pointer">Notificaciones</span>
          </a>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Facturas"
            v-show="tienePermiso('access_to_invoices')">
          <a
              @click="$router.push({name: 'InvoiceView'})"
              class="sidebar-nav-link d-block cursor-pointer"
              :class="getRoute() === 'InvoiceView' ? 'active' : ''">
            <i class="ph ph-money"></i>
            <span class="title cursor-pointer">Facturas</span>
          </a>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 Reportes"
            v-show="tienePermiso('access_to_reports')">
          <a id="Reports_link"
             @click="Show_submenu('Reports')"
             class="accordion-button rounded-0 shadow-none bg-transparent d-block collapsed cursor-pointer"
             data-bs-toggle="collapse"
             data-bs-target="#sidebarCollapseOne"
             aria-expanded="true"
             aria-controls="sidebarCollapseOne"
          >
            <i class="flaticon-document cursor-pointer"></i>
            <span class="title cursor-pointer">Reportes</span>
          </a>
          <div
              id="Reports_submenu"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/openClose" class="sidebar-sub-menu-link">
                    Aperturas y cierres
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/day-income" class="sidebar-sub-menu-link">
                    Ingresos por per&iacute;odo
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/extra-income" class="sidebar-sub-menu-link">
                    Servicios extras por per&iacute;odo
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/agent-income" class="sidebar-sub-menu-link">
                    Ventas por agentes
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/util-life" class="sidebar-sub-menu-link">
                    Vida &uacute;til de autos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/payment-methods" class="sidebar-sub-menu-link">
                    M&eacute;todos de pago
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/sell" class="sidebar-sub-menu-link">
                    Ventas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/units" class="sidebar-sub-menu-link">
                    Pr&oacute;ximas a servicio
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/contracts" class="sidebar-sub-menu-link">
                    Contratos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/reserves" class="sidebar-sub-menu-link">
                    Reservas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/units-state" class="sidebar-sub-menu-link">
                    Estado de unidades
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/carService" class="sidebar-sub-menu-link">
                    Servicio de unidades
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/contractDamages" class="sidebar-sub-menu-link">
                    Veh&iacute;culos con daños
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/expireGPS" class="sidebar-sub-menu-link">
                    Expiraci&oacute;n de GPS
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/expireCirculation" class="sidebar-sub-menu-link">
                    Expiraci&oacute;n de Circulaci&oacute;n
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/auth/reports/expireSecure" class="sidebar-sub-menu-link">
                    Expiraci&oacute;n de Seguro
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item">
          <a @click="Logout" class="sidebar-nav-link d-block cursor-pointer">
            <i class="flaticon-logout"></i>
            <span class="title">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {logoutUser} from "@/actions";

export default {
  name: "MainSidebar",
  data() {
    return {
      abierto: false,
      isConfig: false,
    }
  },
  beforeCreate(){
    this.isConfig = this.$store.state.config
  },
  computed: {
    ...mapState([
      "SidenavOpen"
    ]),
    ...mapGetters(["tienePermiso"]),
    isConfig() {
      return this.$store.state.config;
    },
  },
  methods: {
    onLogout() {

    },
    onChange() {
      this.$store.state.SidenavOpen = !this.$store.state.SidenavOpen
    },
    getRoute() {
      return this.$route.name;
    },
    Show_submenu(id) {
      const submenu = id + '_submenu'
      const link = id + '_link'
      const menu = document.getElementById(link)
      const contenido = document.getElementById(submenu)
      if (menu.classList.contains('collapsed')) {
        menu.classList.remove('collapsed')
        contenido.classList.add('show')
      } else {
        menu.classList.add('collapsed')
        contenido.classList.remove('show')
      }

    },
    Logout() {
      logoutUser(this.$store.state.refresh_token).then(() => {
        $cookies.set("token", null, 15);
        sessionStorage.clear();
        this.$store.state.isAuth = false;
        this.$store.state.refresh_token = null;
        this.$store.state.username = null;
        this.$store.state.email = null;
        this.$store.state.userid = null;
        this.$store.state.firstName = null;
        this.$store.state.lastName = null;
        this.$store.state.config = false;
        this.$router.push({
          name: 'Login'
        });
      }).catch(err => console.log(err));
    }
  },
};
</script>
<style scoped>
</style>
