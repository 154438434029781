<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing ps-4 pe-4">
    <div class="card-head box-shadow bg-white row p-2">
      <div class="col-lg-4 col-sm-12">
        <MazSelect
            v-model="car"
            label="Seleccione un vehículo"
            :options="carSelect"
            search
        />
      </div>
      <div class="col-lg-4 col-sm-12"></div>
      <div class="col-lg-4 col-sm-12 d-flex justify-content-between align-items-center">
        <button class="btn btn-link" @click="loadDatas" target="_blank" title="Buscar ...">
          <i class="flaticon-search"></i>
        </button>
        <button class="btn btn-link" @click="generateReportPDF" target="_blank" title="Descargar reporte en pdf">
          <i class="flaticon-download-circular-button"></i>
        </button>
      </div>
    </div>
    <div ref="report" class="card-body p-15 p-sm-20 p-md-25 bg-light text-start" style="color:#0c0c0c">
      <div class="row text-start mb-20">
        <strong>Informe de vida &uacute;til de los veh&iacute;culos</strong>
        <strong>Fecha:</strong><span>{{ formatearFechaShort(new Date()) }}</span>
      </div>

      <p class="mb-3" style="margin-top:20px"><strong>Daños del veh&iacute;culo {{car}}</strong></p>
      <div class="mb-20">
        <div class="row text-start">
          <div class="col-lg-2 col-sm-6"><strong>No. Contrato</strong></div>
          <div class="col-lg-4 col-sm-6"><strong>Veh&iacute;culo</strong></div>
          <div class="col-lg-3 col-sm-6"><strong>Daño</strong></div>
          <div class="col-lg-3 col-sm-6"><strong>Monto</strong></div>
        </div>
        <div class="row text-start" v-for="(car, index) in damages" :key="index">
          <div class="col-lg-2 col-sm-6">{{ car.contract.contratoNo }}</div>
          <div class="col-lg-4 col-sm-6">{{ car.contract.vehiculo.noEconomico }}</div>
          <div class="col-lg-3 col-sm-6">{{ car.damage }}</div>
          <div class="col-lg-3 col-sm-6">{{ transfMount(car.price) }}</div>
        </div>
      </div>

      <p class="mb-3" style="margin-top:20px"><strong>Mantenimientos del veh&iacute;culo {{car}}</strong></p>
      <div class="row text-start">
        <div class="col-lg-3 col-sm-6"><strong>Fecha de mantenimiento</strong></div>
        <div class="col-lg-3 col-sm-6"><strong>Motivo del mantenimiento</strong></div>
        <div class="col-lg-6 col-sm-6"><strong>Detalles</strong></div>
      </div>
      <div class="row text-start" v-for="(item, index) in repairs" :key="index">
        <div class="col-lg-3 col-sm-6">
          {{ formatearFechaShort(item.dateIn) }} -
          {{ formatearFechaShort(item.dateOut) }}
        </div>
        <div class="col-lg-3 col-sm-6">{{ item.maintenanceReason.reason }}</div>
        <div class="col-lg-6 col-sm-6">{{ item.details }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  getAllCars,
  getCarsDamages,
  getCarsRepair
} from "@/actions";
import {decodeBase64, transfMount} from "@/util";
import html2pdf from "html2pdf.js";
import moment from "moment/moment";
import MazSelect from 'maz-ui/components/MazSelect'
import Swal from "sweetalert2";

export default {
  name: "CarService",
  components: {MazSelect},
  data() {
    return {
      values: null,
      car: 0,
      cars: [],
      carSelect: [],
      damages: [],
      repairs: [],
      date: null,
      loading: false,
      option: "noOption",
    }
  },
  watch: {
    cars() {
      if (this.cars.length > 0) {
        this.carSelect=this.cars.map(item=>{
          return item.noEconomico
        })
      }
    }
  },
  methods: {
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm a");
    },
    formatearFechaShort(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY");
    },
    transfMount,
    async generateReportPDF() {
      this.accion = "print"
      this.loading = true
      setTimeout(() => {
        let base64Data = ""
        const contentToExport = this.$refs.report;
        const options = {
          margin: [17, 5, 20, 5],
          filename: "report.pdf",
          image: {type: "jpeg", quality: 0.98},
          html2canvas: {scale: 2},
          jsPDF: {unit: "mm", format: "a4", orientation: "portrait"}
        };
        html2pdf()
            .from(contentToExport)
            .set(options)
            .output("blob", "reporte.pdf")
            .then(dataUri => {
              const blob = new Blob([dataUri], {type: "application/pdf"});
              const url = URL.createObjectURL(blob);
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                base64Data = reader.result
              }
              window.open(url, "_blank");
            });
      }, 50);
      this.loading = false
    },
    loadCars() {
      getAllCars().then(resp => {
        this.cars = resp.data.data.allVehicle
      })
    },
    loadDatas() {
      if (this.car !== 0) {
        getCarsDamages(this.car).then(response => {
          this.damages = response.data.data.reportCarsWithDamage
        })
        getCarsRepair(this.car).then(response => {
          this.repairs = response.data.data.reportCarsWithRepair
        })
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe seleccionar un vehículo"
        });
      }
    },
  },
  computed: {
    protections() {
      if (this.values) {
        return this.values.protections
      }
    },
    contracts() {
      if (this.values) {
        return this.values.contracts
      }
    },
    totalContracts() {
      let total = 0
      if (this.values) {
        this.values.contracts.forEach(item => {
          total += parseFloat(item.total)
        })
      }
      return total
    },
    totalPaymentType() {
      let total = 0
      if (this.values) {
        total = parseFloat(this.values.totalCash) + parseFloat(this.values.totalCard) + parseFloat(this.values.totalTransfer)
      }
      return total
    },
  },
  mounted() {
    this.loadCars()
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
